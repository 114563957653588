@import "tailwindcss/base";
@import "tailwindcss/components";

/* Additional styles */
@import "additional-styles/utility-patterns.css";
@import "additional-styles/theme.css";

@import "tailwindcss/utilities";

.display-linebreak {
  white-space: pre-line;
}

.text-primary {
  color: #ff5b01;
}
.bg-orange {
  background-color: #eb6e15;
}

.btn {
  font-size: 15px;
}
.btn-invert {
  @apply text-zinc-900;
  @apply bg-white;
  @apply border-2;
  @apply border-orange-600;
  @apply hover:bg-orange-100;
}

.subtitle {
  @apply text-lg;
  @apply text-zinc-800;
}
